<template>
  <div class="page-wrapper msg-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <pending-items-internal :title="false" />
      <div class="page-title">
        <h1>Enrollments CURRENTLY IN PROGRESS!.</h1>
      </div>
      <pending-search @onSearch="searchData" @onClear="clearData" />
      <div class="table-card">
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filters" />
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th v-for="(column, index) in columns" :key="index">
                  {{ column.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="!rows.records" colspan="8" align="center">
                  No records found.
                </td>
              </tr>
              <tr
                v-for="(row, index) in rows.records"
                :key="index"
                :class="rowClass(row)"
              >
                <td>
                  <span class="date">{{ row.date }}</span>
                </td>
                <td>
                  <span class="agent-name">{{ row.agent_name }}</span>
                </td>
                <td>
                  <span class="name">{{ row.user_name }}</span>
                </td>
                <td>
                  <span class="Employer">{{ row.group_name }}</span>
                </td>
                <td>
                  <span class="enroll-type">{{ row.enroll_type }}</span>
                </td>
                <td>
                  <span class="website">{{ row.website }}</span>
                </td>
                <td>
                  <span class="progress-complete">{{ row.progress }}</span>
                </td>
                <td>
                  <span :key="plan?.plan_id" v-for="plan in row.cart_details"><span v-if="plan.plan_id">{{ plan.plan_name }} <strong>${{ plan.price }} </strong></span></span>
                </td>
                <td>
                  <div class="action-icons">
                    <custom-button
                      title="Complete"
                      iconClass="fab fa-wpforms"
                      @onClicked="completeAction(row.link)"
                    />
                    <custom-button
                        title="View Screen Shots"
                        iconClass="fa fa-camera"
                        @onClicked="routeScreenShot(row.id)"
                    />
                    <custom-button
                      title="Resend Enrollment Link"
                      iconClass="fas fa-envelope"
                      @onClicked="resendEnrollmentLink(row)"
                    />
                    <delete-button
                      v-if="row.four_days_prior"
                      @onClicked="deleteRow(row)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="d-flex justify-content-between align-items-center"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filters" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import PendingSearch from "./partials/PendingSearch.vue";
import PendingItemsInternal from "../Dashboard/partials/PendingItemsInternal.vue";
export default {
  name: "PendingEnrollmentList",
  components: { PendingSearch, PendingItemsInternal },
  data: () => ({
    rows: [],
    columns: [
      { label: "Date" },
      { label: "Agent Name" },
      { label: "Name" },
      { label: "Employer/Group" },
      { label: "Enroll Type" },
      { label: "Website" },
      { label: "Progress Completed" },
      { label: "Cart Plans" },
      { label: "Actions" },
    ],
    filters: { search: "",page_size:30 },
    breadcrumbItems: [{ title: "Enrollments", active: true }],
  }),
  methods: {
    completeAction(link) {
      window.open(link, "_blank");
    },
    deleteRow(row) {
      const url = `v2/delete-pending-enrollment`;
      this.$confirm({
        title: "Resend Enrollment Link",
        message: `Do you want to delete this enrollment?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url, { id: row.id, type: row.type })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.getPendingEnrollments(this.filters);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    resendEnrollmentLink(row) {
      const url = `v2/send-link-pending-enrollment`;
      this.$confirm({
        title: "Resend Enrollment Link",
        message: `Do you want to resend enrollment link to ${row.email}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.post(url, { email: row.email, link: row.link })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    routeScreenShot (id) {
      this.$router.push(`/pending/view-screen-shot/${id}`)
    },
    rowClass(row) {
      if (row.four_days_prior) {
        return "danger-row"; //red
      } else if (row.four_days_prior && row.is_saved) {
        return "warning-row"; //orange
      }
    },
    clearData() {
      this.filters.search = "";
      this.getPendingEnrollments(this.filters);
    },
    searchData(query) {
      this.filters.search = query;
      this.getPendingEnrollments(this.filters);
    },
    getPendingEnrollments(filters = null) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/get-pending-enrollments?${filterUrl}`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getPendingEnrollments(this.filters);
  },
};
</script>

<style src="@/assets/css/pending-details.css"></style>

<style scoped>
.simple-search .simpleSearch-field {
  width: 60%;
  border: 1px solid #dce1e7;
  outline: none;
  border-radius: 6px;
  padding: 11px 15px;
  font-size: 13px;
}
.clearBtn {
  width: 15%;
  text-align: center;
  background: #ec3903;
  border: 0px;
  color: #fff;
  padding: 11px;
  border-radius: 6px;
  margin-left: 5px;
  font-size: 14px;
  transition: all ease 0.4s;
}
.simple-search .searchBtn {
  width: 20%;
  text-align: center;
  background: #03c3ec;
  border: 0px;
  color: #fff;
  padding: 11px;
  border-radius: 6px;
  margin-left: 15px;
  font-size: 14px;
  transition: all ease 0.4s;
}
.simple-search .searchBtn:hover {
  background: #08b3d8;
  transition: all ease 0.4s;
}
.simple-search {
  margin-top: 1rem;
}
</style>
