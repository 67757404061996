<template>
  <div class="row">
    <div class="col-xxl-5 col-xl-6 col-lg-9 col-12">
      <div class="pending-search">
        <form @submit.prevent="searchData">
          <input
            type="text"
            class="pendingSearch-field"
            v-model="search"
            placeholder="Search..."
          />
          <button type="submit" class="searchBtn">Search</button>
          <button type="reset" class="clearBtn" @click="clearData">Clear</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PendingSearch",
  data: () => ({
    search: "",
  }),
  methods: {
    clearData() {
      this.search = "";
      this.$emit("onClear");
    },
    searchData() {
      this.$emit("onSearch", this.search);
    },
  },
};
</script>