<template>
  <div class="col-xxl-12 mb-2">
    <div class="dashpending-wrap">
      <div class="dashpending-card-title" v-if="title">
        <h2>Pending Items</h2>
      </div>
      <div class="row">
        <pending-block
          image-name="rep-reg.svg"
          class="col mb-3 h-100"
          title="Enrollments 24hr"
          :value="pendingStats.enrollments_24hrs_count"
          :routeLink="{ name: 'Enrollments24hr' }"
        />

        <pending-block
          image-name="signature.svg"
          title="Signature"
          class="col mb-3 h-100"
          :value="pendingStats.pending_document_count"
          :routeLink="{ name: 'PendingSignatureList' }"
        />

        <pending-block
          image-name="reps.svg"
          title="Reps"
          class="col mb-3 h-100"
          :value="pendingStats.pending_downline_count"
          :routeLink="{
            name: 'PendingDowlineRepList',
          }"
        />

        <pending-block
          image-name="child.svg"
          title="Deps turning 26"
          class="col mb-3 h-100"
          :value="pendingStats.pending_child_aging_off_count"
          :routeLink="{ name: 'PendingDependentsChildList' }"
        />

        <pending-block
          image-name="spouse.svg"
          title="Deps turning 65"
          class="col mb-3 h-100"
          :value="pendingStats.pending_spouse_aging_off_count"
          :routeLink="{ name: 'PendingDependentsSpouseList' }"
        />

        <pending-block
          image-name="old.svg"
          title="Member 65+"
          class="col mb-3 h-100"
          :value="pendingStats.pending_member_turing_65_count"
          :routeLink="{ name: 'PendingMember65List' }"
        />
        <pending-block
          image-name="enrollments.svg"
          title="Enrollments"
          class="col mb-3 h-100"
          :value="pendingStats.pending_enrollment_count"
          :routeLink="{ name: 'PendingEnrollmentList' }"
        />

        <pending-block
          image-name="rep-reg.svg"
          title="Rep Registration"
          class="col mb-3 h-100"
          :value="pendingStats.pending_rep_administrator_count"
          :routeLink="{ name: 'PendingRepRegistrationList' }"
        />

        <pending-block
          image-name="group.svg"
          title="Group Registration"
          class="col mb-3 h-100"
          :value="pendingStats.pending_group_registration_count"
          :routeLink="{ name: 'PendingGroupRegistrationList' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import PendingBlock from "@/components/ui/PendingBlock.vue";
export default {
  components: { PendingBlock },
  name: "PendingItemsInterval",
  props: {
    title: {
      required: false,
      default: true,
    },
  },
  data: () => ({
    pendingScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "600",
      },
      scrollPanel: {
        maxHeight: 400,
      },
      bar: {
        background: "#ddd",
      },
    },
    // stats: null,
  }),
  computed: {
    ...mapGetters({
      pendingStats: "pendingStats",
    }),
    class() {
      return true;
    },
  },
};
</script>
<style>
.pending-content {
   min-height: 30px !important;
}
</style>