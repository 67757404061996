var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-12 mb-2"
  }, [_c('div', {
    staticClass: "dashpending-wrap"
  }, [_vm.title ? _c('div', {
    staticClass: "dashpending-card-title"
  }, [_c('h2', [_vm._v("Pending Items")])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "rep-reg.svg",
      "title": "Enrollments 24hr",
      "value": _vm.pendingStats.enrollments_24hrs_count,
      "routeLink": {
        name: 'Enrollments24hr'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "signature.svg",
      "title": "Signature",
      "value": _vm.pendingStats.pending_document_count,
      "routeLink": {
        name: 'PendingSignatureList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "reps.svg",
      "title": "Reps",
      "value": _vm.pendingStats.pending_downline_count,
      "routeLink": {
        name: 'PendingDowlineRepList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "child.svg",
      "title": "Deps turning 26",
      "value": _vm.pendingStats.pending_child_aging_off_count,
      "routeLink": {
        name: 'PendingDependentsChildList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "spouse.svg",
      "title": "Deps turning 65",
      "value": _vm.pendingStats.pending_spouse_aging_off_count,
      "routeLink": {
        name: 'PendingDependentsSpouseList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "old.svg",
      "title": "Member 65+",
      "value": _vm.pendingStats.pending_member_turing_65_count,
      "routeLink": {
        name: 'PendingMember65List'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "enrollments.svg",
      "title": "Enrollments",
      "value": _vm.pendingStats.pending_enrollment_count,
      "routeLink": {
        name: 'PendingEnrollmentList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "rep-reg.svg",
      "title": "Rep Registration",
      "value": _vm.pendingStats.pending_rep_administrator_count,
      "routeLink": {
        name: 'PendingRepRegistrationList'
      }
    }
  }), _c('pending-block', {
    staticClass: "col mb-3 h-100",
    attrs: {
      "image-name": "group.svg",
      "title": "Group Registration",
      "value": _vm.pendingStats.pending_group_registration_count,
      "routeLink": {
        name: 'PendingGroupRegistrationList'
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }