var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper msg-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('pending-items-internal', {
    attrs: {
      "title": false
    }
  }), _vm._m(0), _c('pending-search', {
    on: {
      "onSearch": _vm.searchData,
      "onClear": _vm.clearData
    }
  }), _c('div', {
    staticClass: "table-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center top-pagination"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.columns, function (column, index) {
    return _c('th', {
      key: index
    }, [_vm._v(" " + _vm._s(column.label) + " ")]);
  }), 0)]), _c('tbody', [_c('tr', [!_vm.rows.records ? _c('td', {
    attrs: {
      "colspan": "8",
      "align": "center"
    }
  }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index,
      class: _vm.rowClass(row)
    }, [_c('td', [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(row.date))])]), _c('td', [_c('span', {
      staticClass: "agent-name"
    }, [_vm._v(_vm._s(row.agent_name))])]), _c('td', [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(row.user_name))])]), _c('td', [_c('span', {
      staticClass: "Employer"
    }, [_vm._v(_vm._s(row.group_name))])]), _c('td', [_c('span', {
      staticClass: "enroll-type"
    }, [_vm._v(_vm._s(row.enroll_type))])]), _c('td', [_c('span', {
      staticClass: "website"
    }, [_vm._v(_vm._s(row.website))])]), _c('td', [_c('span', {
      staticClass: "progress-complete"
    }, [_vm._v(_vm._s(row.progress))])]), _c('td', _vm._l(row.cart_details, function (plan) {
      return _c('span', {
        key: plan === null || plan === void 0 ? void 0 : plan.plan_id
      }, [plan.plan_id ? _c('span', [_vm._v(_vm._s(plan.plan_name) + " "), _c('strong', [_vm._v("$" + _vm._s(plan.price) + " ")])]) : _vm._e()]);
    }), 0), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [_c('custom-button', {
      attrs: {
        "title": "Complete",
        "iconClass": "fab fa-wpforms"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.completeAction(row.link);
        }
      }
    }), _c('custom-button', {
      attrs: {
        "title": "View Screen Shots",
        "iconClass": "fa fa-camera"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.routeScreenShot(row.id);
        }
      }
    }), _c('custom-button', {
      attrs: {
        "title": "Resend Enrollment Link",
        "iconClass": "fas fa-envelope"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.resendEnrollmentLink(row);
        }
      }
    }), row.four_days_prior ? _c('delete-button', {
      on: {
        "onClicked": function onClicked($event) {
          return _vm.deleteRow(row);
        }
      }
    }) : _vm._e()], 1)])]);
  })], 2)])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Enrollments CURRENTLY IN PROGRESS!.")])]);
}]

export { render, staticRenderFns }